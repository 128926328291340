const KeyMirror = require('keymirror');

module.exports = KeyMirror({
    ADD_USER_BEGIN: true,
    ADD_USER_SUCCESS: true,
    ADD_USER_ERROR: true,
    EDIT_USER_DETAILS_BEGIN: true,
    EDIT_USER_DETAILS_SUCCESS: true,
    EDIT_USER_DETAILS_ERROR: true,
    DELETE_USER_BEGIN: true,
    DELETE_USER_SUCCESS: true,
    DELETE_USER_ERROR: true,
    UPDATE_PAGINATED_USERS_PARAMS: true,
    CLEAR_PAGINATED_USERS: true,
    GET_PAGINATED_USERS_BEGIN: true,
    GET_PAGINATED_USERS_SUCCESS: true,
    GET_PAGINATED_USERS_ERROR: true,
    GET_ALL_ATTORNEY_USERS_BEGIN: true,
    GET_ALL_ATTORNEY_USERS_SUCCESS: true,
    GET_ALL_ATTORNEY_USERS_ERROR: true,
    RESEND_USER_INVITE_BEGIN: true,
    RESEND_USER_INVITE_ERROR: true,
    RESEND_USER_INVITE_SUCCESS: true,
    IMPORT_USERS_BEGIN: true,
    IMPORT_USERS_SUCCESS: true,
    IMPORT_USERS_ERROR: true,
    IMPORT_USERS_UPDATE: true,
    RESET_IMPORT_USERS_UPDATE: true,
    GET_USER_COLUMNS_BEGIN: true,
    GET_USER_COLUMNS_SUCCESS: true,
    GET_USER_COLUMNS_ERROR: true,
});
