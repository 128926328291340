const KeyMirror = require('keymirror');

module.exports = KeyMirror({
    API_ERROR: true,
    API_SUCCESS: true,
    API_RESET: true,
    GET_APP_CONFIG_BEGIN: true,
    GET_APP_CONFIG_SUCCESS: true,
    GET_APP_CONFIG_ERROR: true,
    SAVE_ADMIN_CONTENT_BEGIN: true,
    SAVE_ADMIN_CONTENT_SUCCESS: true,
    SAVE_ADMIN_CONTENT_ERROR: true,
    OPEN_CLOSE_MOBILE_NAVIGATION: true,
    GET_LANGUAGES_BEGIN: true,
    GET_LANGUAGES_SUCCESS: true,
    GET_LANGUAGES_ERROR: true,
    GET_JURISDICTIONS_BEGIN: true,
    GET_JURISDICTIONS_SUCCESS: true,
    GET_JURISDICTIONS_ERROR: true,
    UPLOAD_BEGIN: true,
    UPLOAD_SUCCESS: true,
    UPLOAD_ERROR: true,
    UPLOAD_DELETE: true,
    RESET_UPLOAD: true,
    SETUP_UPLOADER: true,
});
