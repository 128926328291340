const KeyMirror = require('keymirror');

module.exports = KeyMirror({
    IMPORT_MATTERS_UPDATE: true,
    RESET_IMPORT_MATTERS_UPDATE: true,
    IMPORT_MATTERS_BEGIN: true,
    IMPORT_MATTERS_SUCCESS: true,
    IMPORT_MATTERS_ERROR: true,
    CREATE_MATTER_UPDATE: true,
    RESET_MATTER_UPDATE: true,
    PUBLISH_MATTER_BEGIN: true,
    PUBLISH_MATTER_SUCCESS: true,
    PUBLISH_MATTER_ERROR: true,
    GET_MATTER_FULL_BEGIN: true,
    GET_MATTER_FULL_SUCCESS: true,
    GET_MATTER_FULL_ERROR: true,
    ADD_NOTE_BEGIN: true,
    ADD_NOTE_SUCCESS: true,
    ADD_NOTE_ERROR: true,
    EDIT_MATTER_BEGIN: true,
    EDIT_MATTER_SUCCESS: true,
    EDIT_MATTER_ERROR: true,
    DOWNLOAD_DOCUMENTS_BEGIN: true,
    DOWNLOAD_DOCUMENTS_SUCCESS: true,
    DOWNLOAD_DOCUMENTS_ERROR: true,
    REQUEST_DOCUMENT_BEGIN: true,
    REQUEST_DOCUMENT_SUCCESS: true,
    REQUEST_DOCUMENT_ERROR: true,
    DELETE_DOCUMENT_BEGIN: true,
    DELETE_DOCUMENT_SUCCESS: true,
    DELETE_DOCUMENT_ERROR: true,
    UPDATE_DOCUMENT_BEGIN: true,
    UPDATE_DOCUMENT_SUCCESS: true,
    UPDATE_DOCUMENT_ERROR: true,
    GET_RECCOMENDED_ATTORNEYS_BEGIN: true,
    GET_RECCOMENDED_ATTORNEYS_SUCCESS: true,
    GET_RECCOMENDED_ATTORNEYS_ERROR: true,
    ASSIGN_ATTORNEYS_BEGIN: true,
    ASSIGN_ATTORNEYS_SUCCESS: true,
    ASSIGN_ATTORNEYS_ERROR: true,
    DELETE_MATTER_BEGIN: true,
    DELETE_MATTER_SUCCESS: true,
    DELETE_MATTER_ERROR: true,
    MARK_MATTER_READY_FOR_REVIEW_BEGIN: true,
    MARK_MATTER_READY_FOR_REVIEW_SUCCESS: true,
    MARK_MATTER_READY_FOR_REVIEW_ERROR: true,
    MARK_MATTER_CLOSED_SUCCESS: true,
    MARK_MATTER_OPEN_BEGIN: true,
    MARK_MATTER_OPEN_SUCCESS: true,
    MARK_MATTER_OPEN_ERROR: true,
    MATTER_OUTCOMES_BEGIN: true,
    MATTER_OUTCOMES_SUCCESS: true,
    MATTER_OUTCOMES_ERROR: true,
    GET_PAGINATED_MATTERS_BEGIN: true,
    GET_PAGINATED_MATTERS_SUCCESS: true,
    GET_PAGINATED_MATTERS_ERROR: true,
    UPDATE_PAGINATED_MATTERS_PARAMS: true,
    CLEAR_PAGINATED_MATTERS: true,
    DOCUMENTS_UPLOADED_BEGIN: true,
    DOCUMENTS_UPLOADED_SUCCESS: true,
    GET_MATTERS_COUNT_BEGIN: true,
    GET_MATTERS_COUNT_SUCCESS: true,
    GET_MATTERS_COUNT_ERROR: true,
    GET_ACTIVITY_BEGIN: true,
    GET_ACTIVITY_SUCCESS: true,
    GET_ACTIVITY_ERROR: true,
    GET_MATTERS_JSON_BEGIN: true,
    GET_MATTERS_JSON_SUCCESS: true,
    GET_MATTERS_JSON_ERROR: true,
    REPORT_ISSUE_BEGIN: true,
    REPORT_ISSUE_SUCCESS: true,
    REPORT_ISSUE_ERROR: true,
    DELETE_MATTER_TYPE_BEGIN: true,
    DELETE_MATTER_TYPE_SUCCESS: true,
    DELETE_MATTER_TYPE_ERROR: true,
    GET_MATTER_TYPES_BEGIN: true,
    GET_MATTER_TYPES_SUCCESS: true,
    GET_MATTER_TYPES_ERROR: true,
    UPDATE_PAGINATED_MATTER_TYPES_PARAMS: true,
    GET_PAGINATED_MATTER_TYPES_BEGIN: true,
    GET_PAGINATED_MATTER_TYPES_SUCCESS: true,
    GET_PAGINATED_MATTER_TYPES_ERROR: true,
    ADD_MATTER_TYPE_BEGIN: true,
    ADD_MATTER_TYPE_SUCCESS: true,
    ADD_MATTER_TYPE_ERROR: true,
    GET_MATTER_TYPE_COLORS_BEGIN: true,
    GET_MATTER_TYPE_COLORS_SUCCESS: true,
    GET_MATTER_TYPE_COLORS_ERROR: true,
    EDIT_MATTER_TYPE_BEGIN: true,
    EDIT_MATTER_TYPE_SUCCESS: true,
    EDIT_MATTER_TYPE_ERROR: true,
    GET_MATTER_COLUMNS_BEGIN: true,
    GET_MATTER_COLUMNS_SUCCESS: true,
    GET_MATTER_COLUMNS_ERROR: true,
    GET_MATTERS_REPORT_BEGIN: true,
    GET_MATTERS_REPORT_SUCCESS: true,
    GET_MATTERS_REPORT_ERROR: true,
    EXPORT_MATTER_BEGIN: true,
    EXPORT_MATTER_SUCCESS: true,
    EXPORT_MATTER_ERROR: true,
    GET_MATTER_MESSAGES_BEGIN: true,
    GET_MATTER_MESSAGES_SUCCESS: true,
    GET_MATTER_MESSAGES_ERROR: true,
    SEND_MATTER_MESSAGE_BEGIN: true,
    SEND_MATTER_MESSAGE_SUCCESS: true,
    SEND_MATTER_MESSAGE_ERROR: true,
    ADD_TIME_RECORD_BEGIN: true,
    ADD_TIME_RECORD_SUCCESS: true,
    ADD_TIME_RECORD_ERROR: true,
    UPDATE_TIME_RECORD_BEGIN: true,
    UPDATE_TIME_RECORD_SUCCESS: true,
    UPDATE_TIME_RECORD_ERROR: true,
    DELETE_TIME_RECORD_BEGIN: true,
    DELETE_TIME_RECORD_SUCCESS: true,
    DELETE_TIME_RECORD_ERROR: true,
    SEARCH_LEGAL_SERVER_BEGIN: true,
    SEARCH_LEGAL_SERVER_SUCCESS: true,
    SEARCH_LEGAL_SERVER_ERROR: true,
    IMPORT_LEGAL_SERVER_BEGIN: true,
    IMPORT_LEGAL_SERVER_ERROR: true,
    IMPORT_LEGAL_SERVER_SUCCESS: true,
    IMPORT_LEGAL_SERVER_UPDATE: true,
    RESET_IMPORT_LEGAL_SERVER_UPDATE: true,
    MARK_MATTER_MESSAGES_READ: true,
    UPDATE_MATTER_STAGE_SUCCESS: true,
    UPDATE_MATTER_STAGE_ERROR: true,
    UPDATE_MATTER_STAGE_BEGIN: true,
});
