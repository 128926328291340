import UsersTypes from '../action-types/users';
import { importUsersInitialState } from '../constants';

const internals = {
    initial: () => ({
        paginatedUsers: {
            params: {
                page: 0,
                pageSize: 10,
                sortBy: 'createdAt',
                sortOrder: 'desc',
            },
            results: null,
            total: null,
        },
        allAttorneyUsers: {},
        allAttorneyUsersStatus: {
            error: false,
            completed: false,
        },
        paginatedUsersStatus: {
            error: false,
            completed: false,
        },
        addUserStatus: {
            error: false,
            completed: false,
        },
        importUsers: importUsersInitialState,
        importUsersStatus: {
            error: false,
            completed: false,
        },
    }),
};

const AppReducer = (stateParam, action) => {
    const state = stateParam || internals.initial();

    const { type, payload } = action;

    switch (type) {
    case UsersTypes.GET_USER_COLUMNS_SUCCESS: {
        return {
            ...state,
            importUsers: {
                ...state.importUsers,
                ...payload, // header: [], firstRow: [], id: #
            },
        };
    }
    case UsersTypes.GET_ALL_ATTORNEY_USERS_BEGIN:
        return {
            ...state,
            allAttorneyUsersStatus: {
                error: false,
                completed: false,
            },
        };
    case UsersTypes.GET_ALL_ATTORNEY_USERS_ERROR:
        return {
            ...state,
            allAttorneyUsersStatus: {
                error: true,
                completed: true,
            },
        };
    case UsersTypes.GET_ALL_ATTORNEY_USERS_SUCCESS:
        return {
            ...state,
            allAttorneyUsersStatus: {
                error: false,
                completed: true,
            },
            allAttorneyUsers: payload,
        };
    case UsersTypes.UPDATE_PAGINATED_USERS_PARAMS: {
        const newState = { ...state };
        newState.paginatedUsers.params = {
            ...state.paginatedUsers.params,
            ...payload,
        };
        return newState;
    }
    case UsersTypes.GET_PAGINATED_USERS_BEGIN:
        return {
            ...state,
            paginatedUsers: {
                ...state.paginatedUsers,
                results: null,
            },
            paginatedUsersStatus: {
                error: false,
                completed: false,
            },
        };
    case UsersTypes.GET_PAGINATED_USERS_ERROR:
        return {
            ...state,
            paginatedUsersStatus: {
                error: true,
                completed: true,
            },
        };
    case UsersTypes.GET_PAGINATED_USERS_SUCCESS:
        return {
            ...state,
            paginatedUsersStatus: {
                error: false,
                completed: true,
            },
            paginatedUsers: {
                ...state.paginatedUsers,
                results: payload.results,
                total: payload.total,
            },
        };
    case UsersTypes.CLEAR_PAGINATED_USERS:
        return {
            ...state,
            paginatedUsersStatus: internals.initial().paginatedUsersStatus,
            paginatedUsers: internals.initial().paginatedUsers,
        };
    case UsersTypes.ADD_USER_BEGIN:
        return {
            ...state,
            addUserStatus: {
                error: false,
                completed: false,
            },
        };
    case UsersTypes.ADD_USER_SUCCESS:
        return {
            ...state,
            addUserStatus: {
                error: false,
                completed: true,
            },
        };
    case UsersTypes.ADD_USER_ERROR:
        return {
            ...state,
            addUserStatus: {
                error: true,
                completed: true,
            },
        };
    case UsersTypes.IMPORT_USERS_BEGIN:
        return {
            ...state,
            importUsersStatus: {
                error: false,
                completed: false,
            },
        };
    case UsersTypes.IMPORT_USERS_SUCCESS:
        return {
            ...state,
            importUsersStatus: {
                error: false,
                completed: true,
            },
        };
    case UsersTypes.IMPORT_USERS_UPDATE:
        return {
            ...state,
            importUsers: {
                ...state.importUsers,
                ...payload,
            },
        };
    case UsersTypes.RESET_IMPORT_USERS_UPDATE:
        return {
            ...state,
            importUsers: importUsersInitialState,
            importUsersStatus: {
                error: false,
                errorMsg: '',
                completed: false,
            },
        };

    default:
        // do nothing
    }
    return state;
};

export default AppReducer;
