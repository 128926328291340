export const groomPayload = (values) => {
    const payload = { ...values };

    // groom data based on current selection
    // the conditionals include userTypes for whitelabel and ST
    if (payload.userType !== 'Partner Attorney' && payload.userType !== 'Client Attorney') payload.partnerId = null;
    if (payload.userType !== 'Referrer' && payload.userType !== 'LSO') payload.referrerId = null;

    // deduce role from selected userType
    if (payload.userType === 'Administrator') payload.role = 'admin';
    else if (payload.userType === 'Referrer' || payload.userType === 'LSO') payload.role = 'referrer';

    // this accounts for both Internal and Partner Attorneys
    else payload.role = 'lawyer';
    if (payload.userType) delete payload.userType;

    return payload;
};

export const determinUserRole = (userType) => {
    // deduce role from selected userType
    if (userType === 'Administrator') return { role: 'admin', lawyerType: null };
    if (userType === 'Referrer' || userType === 'LSO') return { role: 'referrer', lawyerType: null };

    // lawyers also get "lawyerType" param values
    if (userType === 'Partner Attorney') return { role: 'lawyer', lawyerType: 'partner' };
    if (userType === 'Client Attorney') return { role: 'lawyer', lawyerType: 'internal' };

    // everything else is invalid
    return { role: null, lawyerType: null };
};
