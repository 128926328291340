import AppTypes from '../action-types/app';
import MatterTypes from '../action-types/matters';
import UsersTypes from '../action-types/users';
import WebClient from '../utils/web-client';
import prepareContentType from '../utils/prepare-content-type';

export const OpenMobileNavigation = (payload = null) => ({
    type: AppTypes.OPEN_CLOSE_MOBILE_NAVIGATION,
    payload,
});

export const ResetAPIStatus = () => ({
    type: AppTypes.API_RESET,
});

export const APIError = (text = null, autoHideDisabled = null) => ({
    type: AppTypes.API_ERROR,
    payload: {
        text,
        autoHideDisabled,
    },
});

export const APISuccess = (text = null, autoHideDisabled = null) => ({
    type: AppTypes.API_SUCCESS,
    payload: {
        text,
        autoHideDisabled,
    },
});

export const GetAppConfigBegin = () => ({
    type: AppTypes.GET_APP_CONFIG_BEGIN,
});
const GetAppConfigSuccess = (payload) => ({
    type: AppTypes.GET_APP_CONFIG_SUCCESS,
    payload,
});
const GetAppConfigError = (errorMsg) => ({
    type: AppTypes.GET_APP_CONFIG_ERROR,
    payload: { errorMsg },
});

export const GetAppConfig = () => async (dispatch) => {
    dispatch(GetAppConfigBegin());
    try {
        const { data } = await WebClient.get('/global-config');
        dispatch(GetAppConfigSuccess(data));
    } catch (error) {
        dispatch(GetAppConfigError(error));
    }
};

const SaveAdminContentBegin = () => ({
    type: AppTypes.SAVE_ADMIN_CONTENT_BEGIN,
});
const SaveAdminContentSuccess = (payload) => ({
    type: AppTypes.SAVE_ADMIN_CONTENT_SUCCESS,
    payload,
});
const SaveAdminContentError = () => ({
    type: AppTypes.SAVE_PRIVACY_POLICY_ERROR,
});

export const SaveAdminContent = (content, type, onSuccess) => async (dispatch) => {
    dispatch(SaveAdminContentBegin());
    try {
        const { data } = await WebClient.patch(`/global-config/${type}`, { content });
        dispatch(APISuccess('Content successfully edited.'));
        dispatch(SaveAdminContentSuccess({ data, type }));
        if (onSuccess) onSuccess();
    } catch (error) {
        dispatch(SaveAdminContentError());
        dispatch(APIError('Error editing content.'));
    }
};

const GetLanguagesBegin = () => ({
    type: AppTypes.GET_LANGUAGES_BEGIN,
});
const GetLanguagesSuccess = (payload) => ({
    type: AppTypes.GET_LANGUAGES_SUCCESS,
    payload,
});
const GetLanguagesError = () => ({
    type: AppTypes.GET_LANGUAGES_ERROR,
});

export const GetLanguages = () => async (dispatch) => {
    dispatch(GetLanguagesBegin());
    try {
        const { data } = await WebClient.get('/languages');
        dispatch(GetLanguagesSuccess(data));
    } catch (error) {
        dispatch(GetLanguagesError());
    }
};

const GetJurisdictionsBegin = () => ({
    type: AppTypes.GET_JURISDICTIONS_BEGIN,
});
const GetJurisdictionsSuccess = (payload) => ({
    type: AppTypes.GET_JURISDICTIONS_SUCCESS,
    payload,
});
const GetJurisdictionsError = () => ({
    type: AppTypes.GET_JURISDICTIONS_ERROR,
});

export const GetJurisdictions = () => async (dispatch) => {
    dispatch(GetJurisdictionsBegin());
    try {
        const { data } = await WebClient.get('/jurisdictions');
        dispatch(GetJurisdictionsSuccess(data));
    } catch (error) {
        dispatch(GetJurisdictionsError());
    }
};

// Documents and bulk uplaoders
export const UploadDocumentBegin = (payload) => ({
    type: AppTypes.UPLOAD_BEGIN,
    payload,
});
export const UploadDocumentSuccess = (payload) => ({
    type: AppTypes.UPLOAD_SUCCESS,
    payload,
});
export const UploadDocumentError = (payload) => ({
    type: AppTypes.UPLOAD_ERROR,
    payload,
});

export const ResetUploader = () => ({
    type: AppTypes.RESET_UPLOAD,
});

export const SetupUploader = (payload) => ({
    type: AppTypes.SETUP_UPLOADER,
    payload,
});

export const DeleteUpload = (payload) => ({
    type: AppTypes.UPLOAD_DELETE,
    payload,
});

export const UploadDocument = (file, matterId, onSuccess, onError, listIndex) => async (dispatch) => {
    const payload = {
        name: file.name,
        uploadErrMsg: null,
        status: 'inprogress',
        listIndex,
    };
    dispatch(UploadDocumentBegin(payload));

    const [formData, config] = prepareContentType('form-data', { doc: file });
    if (matterId) formData.matterId = matterId;
    try {
        const uploadFile = await WebClient.post('/documents', formData, config);
        const payloadSuccess = {
            listIndex,
            id: uploadFile.data.id,
            name: file.name,
            title: file.name,
            status: 'success',
        };
        dispatch(UploadDocumentSuccess(payloadSuccess));
    } catch (error) {
        const payloadError = {
            listIndex,
            id: Math.floor(Math.random() * (1000 - 1 + 1) + 1),
            name: file.name,
            title: file.name,
            status: 'error',
            errMsg: error.message,
            // uploadStatus: 'error',
            uploadErrMsg: 'Failed',
        };
        dispatch(UploadDocumentError(payloadError));
    }
};

const GetMatterColumnDataBegin = () => ({
    type: MatterTypes.GET_MATTER_COLUMNS_BEGIN,
});
const GetMatterColumnDataSuccess = (payload) => ({
    type: MatterTypes.GET_MATTER_COLUMNS_SUCCESS,
    payload,
});
const GetMatterColumnDataError = () => ({
    type: MatterTypes.GET_MATTER_COLUMNS_ERROR,
});

const GetUserColumnDataBegin = () => ({
    type: UsersTypes.GET_USER_COLUMNS_BEGIN,
});
const GetUserColumnDataSuccess = (payload) => ({
    type: UsersTypes.GET_USER_COLUMNS_SUCCESS,
    payload,
});
const GetUserColumnDataError = () => ({
    type: UsersTypes.GET_USER_COLUMNS_ERROR,
});

export const GetColumnData = (incomingData) => async (dispatch) => {
    const { file, type } = incomingData;
    // type is either 'user' or 'matter'
    if (type === 'bulkUsers') dispatch(GetUserColumnDataBegin());
    else if (type === 'bulkMatters') dispatch(GetMatterColumnDataBegin());
    const payload = {
        name: file.name,
        uploadErrMsg: null,
        status: 'inprogress',
        listIndex: 0,
    };
    dispatch(UploadDocumentBegin(payload));

    const [formData, config] = prepareContentType('form-data', { file });
    try {
        const { data } = await WebClient.post('/imports/columns', formData, config);
        if (type === 'bulkUsers') dispatch(GetUserColumnDataSuccess(data));
        else if (type === 'bulkMatters') dispatch(GetMatterColumnDataSuccess(data));
        const payloadSuccess = {
            listIndex: payload.listIndex,
            id: data.id,
            name: payload.name,
            title: payload.name,
            status: 'success',
        };
        dispatch(UploadDocumentSuccess(payloadSuccess));
    } catch (error) {
        dispatch(APIError());
        if (type === 'bulkUsers') dispatch(GetUserColumnDataError());
        else if (type === 'bulkMatters') dispatch(GetMatterColumnDataError());

        const payloadError = {
            listIndex: payload.listIndex,
            id: Math.floor(Math.random() * (1000 - 1 + 1) + 1),
            name: payload.name,
            title: payload.name,
            status: 'error',
            errMsg: error.message,
            // uploadStatus: 'error',
            uploadErrMsg: 'Failed',
        };
        dispatch(UploadDocumentError(payloadError));
    }
};
